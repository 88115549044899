import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "source" ]
	initialize() {

	}
	toggle() {
		event.preventDefault()
		event.currentTarget.parentElement.children[1].classList.toggle('is-hidden')
		event.currentTarget.children[1].classList.toggle('opened')
	}
	togglePillar() {
		event.preventDefault()
		event.currentTarget.parentElement.children[1].classList.toggle('is-hidden-pillar')
		event.currentTarget.children[1].classList.toggle('opened')
	}
	toggleDiscoveryCard() {
		event.preventDefault()

		for (let i=0; i < event.currentTarget.parentElement.children.length; i++) {
			event.currentTarget.parentElement.children[i].classList.remove('card--active')
		}
		event.currentTarget.classList.add('card--active')
	}
	toggleScheduleSlot() {
		event.preventDefault()
		event.currentTarget.classList.toggle('opened')
	}
	toggleFaq() {
		// event.preventDefault()
		event.currentTarget.classList.toggle('opened')
	}
}
