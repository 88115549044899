import Rails from "@rails/ujs"
import _, { map } from 'underscore'
import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {
		this.processing_checkbox = false
		this.saveTextarea = this.saveTextarea.bind(this)
	}
	connect() {
		this.saveTextarea = _.debounce(this.saveTextarea, 1000)
	}
	toggleCheckbox(e) {
		// e.preventDefault()
		// Updates destroy based on checked checkbox
		this.updateCheckboxDestroyField(e)
	}
	saveTextarea(e) {
		var t = this
		var notification = document.getElementsByClassName("notification")
		var favedIcon = document.getElementsByClassName("icon-favorite")
		var allTextareas = document.getElementsByClassName("hidden-field--textarea")
		const form = this.element.closest("form")
		var data = new FormData(form)

		var actionType = "post"
		if (form.action.replace(/^\D+/g, '') != "") {
			actionType = "put"
		}

		Rails.ajax({
				url: form.action,
				type: actionType,
				data: data,
				success: function(data) {
					if (actionType == "post") {
						notification[0].classList.remove("is-invisible")
						notification[0].classList.add("slide-out-blurred-top")
						if (!favedIcon[0].classList.contains("icon-favorite--faved")) {
							Rails.ajax({
								url: favedIcon[0].getAttribute("href"),
								type: "post",
								data: {id: favedIcon[0].getAttribute("data-id")}
							})
						}
					}
					if (data.length) {
						for (let i = 0; i < data.length; i++) {
							for (let y = 0; y < allTextareas.length; y++) {
								if (data[i]["field_id"] == allTextareas[y].value) {
									var hiddenTextareaId = t.getNextSibling(allTextareas[y], ".hidden-answer")
									hiddenTextareaId.value = data[i]["id"]
								}
							}
						}
						form.action = "/submissions/" + data[0]["submission_id"]
					}
				},
				error: function(data) {

				}
			})

	}
	updateCheckboxDestroyField(checkbox) {
		var notification = document.getElementsByClassName("notification")
		var favedIcon = document.getElementsByClassName("icon-favorite")
		var t = this
		// Get hidden fields for destroy, hidden answer id, and hidden field id, also get the checked message to display it.
		var hiddenDestroyField = this.getNextSibling(checkbox.currentTarget, ".hidden-destroy-field")
		var checkedMessage = this.getNextSibling(checkbox.currentTarget, ".checked-message")
		var hiddenCheckboxId = this.getNextSibling(checkbox.currentTarget, ".hidden-answer")
		var hiddenFieldId = this.getNextSibling(checkbox.currentTarget, ".hidden-field")
		// Get form
		const form = this.element.closest("form")


		if (checkbox.target.checked) {
			// If checkbox is checked, set destroy field to 0, so it won't destroy the created field, display message and get updated form data to submit.
			hiddenDestroyField.value = 0
			checkedMessage.classList.add("show")
			checkbox.target.classList.add("animate-checkbox")
			var data = new FormData(form)
		} else {
			// if unchecked then set destroy to 1 to delete record, remove display message and get update form data.
			hiddenDestroyField.value = 1
			checkedMessage.classList.remove("show")
			var data = new FormData(form)
		}
		// Set hidden answer id to zero so I don't remove a already deleted record from the db
		hiddenCheckboxId.value = ""
		// set action for ajax form to post for the first time submission, then to update to update record and adding answer to the same submission id belonging to user. I'm sure there is a better way to do this.
		var actionType = "post"
		if (form.action.replace(/^\D+/g, '') != "") {
			actionType = "put"
		}

		Rails.ajax({
			url: form.action,
			type: actionType,
			data: data,
			success: function(data) {
				if (actionType == "post") {
					notification[0].classList.remove("is-invisible")
					notification[0].classList.add("slide-out-blurred-top")
					if (!favedIcon[0].classList.contains("icon-favorite--faved")) {
						Rails.ajax({
							url: favedIcon[0].getAttribute("href"),
							type: "post",
							data: {id: favedIcon[0].getAttribute("data-id")}
						})
					}
				}
				if (data.length) {
					// Reverse data to get last record added for this user/submission
					data.reverse()
					// If the return record match the record I submitted then add the answer id to the checkbox answer id field
					if (hiddenFieldId.value == data[0]["field_id"]) {
						hiddenCheckboxId.value = data[0]["id"]
					} else {
						// if not make sure the checkbox does not have any answer id.
						hiddenCheckboxId.value = ""
					}
					// update form action for the first time I submit a form to know I'm submitting a post or a put.
					form.action = "/submissions/" + data[0]["submission_id"]
				} else {
					// if data returns empty, then make sure the answer id for that checkbox is empty.
					hiddenCheckboxId.value = ""
				}
			},
			error: function(data) {

			}
		})
	}
	getNextSibling(elem, selector) {
		var sibling = elem.nextElementSibling;
		while (sibling) {
			if (sibling.matches(selector)) return sibling
			sibling = sibling.nextElementSibling
		}
	}
}
