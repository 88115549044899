import { Controller } from "stimulus"

export default class extends Controller {
	connect() {

	}
	preview() {

		const preview = document.getElementsByClassName("hero-preview")[0];
		const file = document.querySelector("input[type=file]").files[0];
		const reader = new FileReader();

		reader.addEventListener("load", function() {
			// convert image file to base64 string
			preview.src = reader.result;
		}, false);

		if (file) {
			reader.readAsDataURL(file);
		}
	}
}
