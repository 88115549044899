import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
	initialize() {
		this.startRedactorX();

	}
	start() {
		this.startRedactorX();
	}
	startRedactorX() {

		RedactorX('.admin-editor', {
			plugins: ['clips', 'counter'],
			image: {
				upload: '/redactor/image',
				url: false,
				data: {
					'authenticity_token': Rails.csrfToken()
				}
			},
			addbar: {
				hide: ['paragraph', 'pre', 'embed', 'line', 'table', 'quote']
			},
			format: ['p', 'h2', 'ul', 'ol'],
			formatAdd: {
				   "bulleted-body-kelly-green": {
						title: 'Bulleted Body Kelly Green',
						type: 'ul',
						params: {
							tag: 'ul',
							classname: 'bulleted-body-kelly-green'
						}
					}
				},
			clips: {
				items: {
					"header-normal-text": {
						title: 'Header & Normal Text',
						html: '<h2>Header Placeholder</h2><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>'
					},
					"intro-outro": {
						title: 'Intro/Outro',
						html: '<h4 class="intro-outro">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>'
					},
					"listicle-header": {
						title: 'Listicle Header',
						html: '<span class="listicle-header"><span class="listicle-header-h1">1</span><span class="listicle-header-h2">Lorem ipsum dolor sit amet</span></span>'
					},
					"ruled-body": {
						title: 'Ruled Body',
						html: '<ul class="ruled-body"><li><b>Lorem ipsum dolor - </b>sed do eiusmod tempor incididunt ut labore et</li><li><b>Lorem ipsum dolor - </b>sed do eiusmod tempor incididunt ut labore et</li></ul>'
					},
					"bold-body": {
						title: 'Bold Subs',
						html: '<p class="bold-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>'
					},
					"bold-body-subs": {
						title: 'Body with Green Subs',
						html: '<span class="bold-body-subs"><span class="bold-body-subs-h4">1. Lorem ipsum dolor sit amet</span><span class="bold-body-subs-p">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></span>'
					},
					"pull-quote": {
						title: 'Pull Quote',
						html: '<h3 class="pull-quote">Lorem ipsum dolor sit amet, consectetur adipisicing elit</h3>'
					}
				}
			},
			editor: {
				maxHeight: '800px'
			}
		});
	}
}
