import { Controller } from "stimulus"

export default class extends Controller {
	// static targets = [ "source" ]
	initialize() {

	}
	toggle(event) {
		event.preventDefault()
		event.currentTarget.parentElement.parentElement.classList.toggle('is-active')
	}
}
