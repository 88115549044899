import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["nav", "filter", "checkboxes"]
	initialize() {

	}
	toggle(event) {
		event.preventDefault()
		event.currentTarget.children[0].classList.toggle('is-active')
		this.navTarget.classList.toggle('is-active')
	}
	toggleFilter(event){
		event.preventDefault()
		this.filterTarget.classList.toggle('is-active')
	}
	clearFilter(event) {
		event.preventDefault()
		this.checkboxesTargets.forEach(function(checkbox, i){
			checkbox.checked = false
		})
	}
	readTime(event) {
		let readTime = event.currentTarget.getAttribute('data-value')

		switch (readTime) {
			case "5":
				this.setReadFromToValues(0, 5)
				break
			case "15":
				this.setReadFromToValues(5, 15)
				break
			case "30":
				this.setReadFromToValues(15, 30)
				break
			case "30+":
				this.setReadFromToValues(30, 200)
				break
			default:
				this.setReadFromToValues(0, 200)

		}

		event.preventDefault()
	}
	setReadFromToValues(inputFromValue, inputToValue) {
		let readTimeFromInput = document.getElementById("read_time_from")
		let readTimeToInput = document.getElementById("read_time_to")
		readTimeFromInput.value = inputFromValue
		readTimeToInput.value = inputToValue
	}
}
