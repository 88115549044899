import { Controller } from "stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
	// Add a click event on buttons to open a specific modal
	(document.querySelectorAll('.modal-trigger') || []).forEach(($trigger) => {
	  const modal = $trigger.dataset.target;
	  const content = $trigger.dataset.content;
	  const $target = document.getElementById(modal);
	  const $content = document.getElementById(content);

	  $trigger.addEventListener('click', () => {
		if ($content == null) {
			this.openModal($target);
		} else {
			this.openModalContent($target, $content)
		}
	  });
	});

	// Add a click event on various child elements to close the parent modal
	(document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
	  const $target = $close.closest('.modal');

	  $close.addEventListener('click', () => {
		this.closeModal($target);
	  });
	});

	// Add a keyboard event to close all modals
	document.addEventListener('keydown', (event) => {
	  const e = event || window.event;

	  if (e.keyCode === 27) { // Escape key
		this.closeAllModals();
	  }
	});

	// Show registration popup once after some delay
	const $registerModal = document.getElementById("register-modal");
	if ($registerModal && !this.getCookie('registration_live_cta')) {
		setTimeout(() => { this.openModal($registerModal) }, 7000);
		document.cookie = `registration_live_cta=true; path=/; max-age=${60 * 60 * 24 * 7};`;
	}

  }

  openModal($el) {
	$el.classList.add('is-active');
  }

  closeModal($el) {
	$el.classList.remove('is-active');
  }

  closeAllModals() {
	(document.querySelectorAll('.modal') || []).forEach(($modal) => {
	  this.closeModal($modal);
	});
  }
  openModalContent($modal, $content) {
	  $modal.classList.add('is-active')
	  var modalContent = $modal.querySelector('.modal-content-inner')
	  modalContent.innerHTML = $content.innerHTML
  }
  getCookie(name) {
	  let value = `; ${document.cookie}`;
	  let parts = value.split(`; ${name}=`);
	  if (parts.length === 2) return parts.pop().split(';').shift();
  }
}
