import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "source", "clipboardText" ]

	copy(event) {
		event.preventDefault()
		let url = this.sourceTarget.getAttribute("data-url")
		let body = document.getElementsByTagName("body")[0]
		let tempInput = document.createElement("input")
		body.appendChild(tempInput)
		tempInput.setAttribute("value", url)
		tempInput.select()
		document.execCommand("copy")
		body.removeChild(tempInput)
		this.clipboardTextTarget.innerHTML = "Copied!"
	}
}
