import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = ['form', 'submitButton', 'thankYouMessage', 'formInputs']
	static values = { url: String }

	connect() {

	}
	submit() {
		event.preventDefault()
		let fields = document.querySelectorAll(".input--name, .input--email, .textarea--message")
		let isValid = this.validateFields(fields);

		if (isValid) {
			event.preventDefault()
			Rails.ajax({
				url: this.urlValue,
				type: "POST",
				data: new FormData(this.formTarget),
				success: (data) => {
					this.submitButtonTarget.disabled = true
					this.formInputsTarget.classList.add("is-hidden")
					this.thankYouMessageTarget.classList.remove("is-hidden")
				}
			})
		}
	}
	validateFields(fields) {
		let isValid = true
		let errorMessages = document.querySelectorAll('.help.is-danger')

		if (errorMessages.length) {
			for (let i = 0; i < errorMessages.length; i++) {
				errorMessages[i].parentNode.removeChild(errorMessages[i])
			}
		}

		fields.forEach((field) => {
			let inputType = field.type.toLowerCase()
			switch (inputType) {
				case "text":
					if (!field.value.trim()) {
						this.addErrorMessage(field, "This field is required.")
						isValid = false
					} else {
						field.classList.remove('is-danger')
					}
					break
				case "email":
					var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

					if (!regex.test(field.value)) {
						this.addErrorMessage(field, "This field is required. Make sure you enter a valid email.")
						isValid = false
					} else {
						field.classList.remove('is-danger')
					}
					break
				case "textarea":
					if (!field.value.trim()) {
						this.addErrorMessage(field, "This field is required.")
						isValid = false
					} else {
						field.classList.remove('is-danger')
					}
					break
				case "select-one":
					if (!field.value.trim()) {
						this.addErrorMessage(field, "This field is required.")
						isValid = false
					} else {
						field.classList.remove('is-danger')
					}
					break
				default:
					// default
			}

		});

		return isValid;
	}
	addErrorMessage(field, message) {
		let errorMessageEmpty = '<p class="help is-danger">' + message + '</p>'
		field.classList.add('is-danger')
		field.parentNode.outerHTML += errorMessageEmpty
	}
	checkEmptyField(field) {
		if (!field.value.trim()) {
			this.addErrorMessage(field, "This field is required.")
			return false
		} else {
			field.classList.remove('is-danger')
		}
	}
}
