import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['btnMonthly', 'btnYearly']
	connect() {

	}
	pickMonthlyPlan() {
		let planId = document.querySelector("meta[name='product-monthly-id']").getAttribute("content")
		this.btnYearlyTarget.classList.remove('is-primary', 'is-focused')
		this.btnMonthlyTarget.classList.add('is-primary', 'is-focused')
		this.setPlanValue(planId)
	}
	pickYearlyPlan() {
		let planId = document.querySelector("meta[name='product-yearly-id']").getAttribute("content")
		this.btnYearlyTarget.classList.add('is-primary', 'is-focused')
		this.btnMonthlyTarget.classList.remove('is-primary', 'is-focused')
		this.setPlanValue(planId)
	}
	setPlanValue(planId) {
		document.getElementById('plan').value = planId;
	}
}

