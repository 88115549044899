import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {

	}
	hide() {
		event.preventDefault()
		event.currentTarget.parentNode.closest('.notification').classList.add('is-hidden')
	}
}
