import { Controller } from "stimulus"

const enterKey = 13

export default class extends Controller {
	static targets = [ "query"]
	static values = {
		url: String
	}
	connect() {
		this.mainSearch = document.getElementById('main-search')
	}
	showInputSearch() {
		var searchInput = document.getElementById('search_query')
		this.mainSearch.classList.remove('is-hidden')
		searchInput.focus()
	}
	hideInputSearch() {
		if (event.keyCode == 27) {
		  this.mainSearch.classList.add('is-hidden')
		}
	}
	search(event) {
		let iconSearchClicked = event.currentTarget.classList.contains('icon-search-active')
		let searchQuery = this.queryTarget.value
		const url = new URL(this.urlValue)
		url.searchParams.append("query", searchQuery)

		if ((event.keyCode == 13 || iconSearchClicked) && (searchQuery !== "")) {
			Turbo.visit(url)
		}

	}
}
