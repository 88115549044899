import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		let pillarSelections = document.getElementsByClassName("pillar_checkbox")
		for (let i = 0; i < pillarSelections.length; i++) {
			if (pillarSelections[i].checked) {
				this.toggleGoals(pillarSelections[i].id)
			}
		}
	}
	toggleGoals(pillarCssId) {
		let goals
		switch (pillarCssId) {
			case "document_pillar_ids_1":
				goals = document.getElementById("column--pillar-1-goals")
				goals.classList.toggle('active-goals')
				this.toggleDisabledGoals(pillarCssId)
			break;
			case "document_pillar_ids_2":
				goals = document.getElementById("column--pillar-2-goals")
				goals.classList.toggle('active-goals')
				this.toggleDisabledGoals(pillarCssId)
			break;
			case "document_pillar_ids_3":
				goals = document.getElementById("column--pillar-3-goals")
				goals.classList.toggle('active-goals')
				this.toggleDisabledGoals(pillarCssId)
			break;
			case "document_pillar_ids_4":
				goals = document.getElementById("column--pillar-4-goals")
				goals.classList.toggle('active-goals')
				this.toggleDisabledGoals(pillarCssId)
			break;

		}
	}
	togglePillarGoals() {
		this.toggleGoals(event.currentTarget.id)
		this.uncheckSelectedGoals(event.currentTarget.id)
		// this.toggleDisabledGoals(event.currentTarget.id)
	}
	toggleDisabledGoals(pillarCssId){
		let goals, goalsParent
		switch (pillarCssId) {
			case "document_pillar_ids_1":
				goalsParent = document.getElementById("column--pillar-1-goals")
				goals = goalsParent.getElementsByClassName("check_boxes")
			break;
			case "document_pillar_ids_2":
				goalsParent = document.getElementById("column--pillar-2-goals")
				goals = goalsParent.getElementsByClassName("check_boxes")
			break;
			case "document_pillar_ids_3":
				goalsParent = document.getElementById("column--pillar-3-goals")
				goals = goalsParent.getElementsByClassName("check_boxes")
			break;
			case "document_pillar_ids_4":
				goalsParent = document.getElementById("column--pillar-4-goals")
				goals = goalsParent.getElementsByClassName("check_boxes")
			break;
		}
		for (let i = 0; i < goals.length; i++) {
			if (goalsParent.classList.contains("active-goals")) {
				goals[i].disabled = false;
			} else {
				goals[i].disabled = true;
			}
		}

	}
	uncheckSelectedGoals(pillarCssId) {
		let goals
		switch (pillarCssId) {
			case "document_pillar_ids_1":
				goals = document.getElementById("column--pillar-1-goals").getElementsByClassName("check_boxes")
			break;
			case "document_pillar_ids_2":
				goals = document.getElementById("column--pillar-2-goals").getElementsByClassName("check_boxes")
			break;
			case "document_pillar_ids_3":
				goals = document.getElementById("column--pillar-3-goals").getElementsByClassName("check_boxes")
			break;
			case "document_pillar_ids_4":
				goals = document.getElementById("column--pillar-4-goals").getElementsByClassName("check_boxes")
			break;
		}
		for (let i = 0; i < goals.length; i++) {
			if (goals[i].checked) {
				goals[i].checked = false
			}
		}
	}
}
