import { Controller } from "stimulus"
import Plyr from "plyr"

export default class extends Controller {
	initialize() {
		let controlsAudio = ['play-large','rewind','play','fast-forward','progress','current-time','duration']
		let controlsVideo = ['play-large','play','progress','current-time','duration','fullscreen']
		let keyboard = { focused: true, global: false }

		const audioPlayer = new Plyr('#plyr-audio-player', {
			controls: controlsAudio,
			keyboard: keyboard,
			muted: true,
			volume: 0
		})
		const videoPlayer = new Plyr('#plyr-video-player', {
			controls: controlsVideo,
			keyboard: keyboard
		})
		const videoPlayerBg = new Plyr('#plyr-video-player-background', {
			controls: [],
			autoplay: true,
			muted: true,
			hideControls: true,
			volume: 0,
			loop: {active: true},
			vimeo: {muted: true, quality: "720p"}
		})

    const videoPlayerTestimonial = new Plyr('#plyr-video-player-testimonial', {
      controls: controlsVideo,
      vimeo: {muted: false, quality: "1080p"}
    })

		videoPlayer.on('ready', () => {
			videoPlayer.muted = false
		});

		audioPlayer.on('ready', () => {
			audioPlayer.muted = false
			audioPlayer.volume = 1
		});

		videoPlayerBg.on('ready', () => {
			videoPlayerBg.muted = true
			videoPlayerBg.poster = "https://ik.imagekit.io/2fklu267fcx/fierce-public/FierceLabLive_2023-1892_seYJKwgXQ.jpg?updatedAt=1708704455039"
		});
	}
}
