import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
	static targets = ['target', 'templatetextarea', 'templatecheckbox', 'templatevideo', 'templateaudio']

	connect() {
		super.connect();
	}
	add(e) {
		e.preventDefault();
		let template
		switch (e.target.dataset.value) {
			case 'textarea':
				template = this.templatetextareaTarget
				break;
			case 'checkbox':
				template = this.templatecheckboxTarget
				break;
			case 'video':
				template = this.templatevideoTarget
				break;
			case 'audio':
				template = this.templateaudioTarget
				break;
			default:
				template = this.templatetextareaTarget
		}
		const t = template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
		this.targetTarget.insertAdjacentHTML('beforebegin', t);
	}
}
