import { Application } from "stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = true
window.Stimulus      = application

import Bridge from "../turbo/bridge.js";
window.bridge = Bridge;

export { application }
