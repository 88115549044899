import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {

	}
	slider() {
		let rankingNames = ["Meh", "Okay", "Given", "Important", "Crucial"]
		let currentRanking = event.currentTarget.value

		event.currentTarget.parentNode.children[0].value = currentRanking
		event.currentTarget.parentNode.children[3].innerHTML = rankingNames[currentRanking]

	}
}
