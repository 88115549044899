import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["menu"]
	connect() {

	}
	open(event) {
		event.preventDefault()
		this.menuTarget.classList.remove('is-invisible')
	}
	close(event) {
		this.menuTarget.classList.add('is-invisible')
	}
	toExit(event) {
		if(this.element === event.target || this.element.contains(event.target)) return;
		this.close(event)
	}
}
