window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

document.addEventListener("turbo:load", function(event){
	if(typeof(gtag) == "function") {
		gtag('config', 'G-0CD44SNSKB', {
			page_location: event.detail.url,
			// page_path: event.path[1].location.pathname,
			page_title: event.srcElement.title

		});
	}

});


export default gtag
